<template>
  <div>

    <div>
      <b-button variant="primary" @click="showModal">
        {{ $t('common.select') }}
      </b-button>
      <b-button v-if="items.length > 0" variant="secondary" class="ml-1" @click="clearItems">
        {{ $t('common.clear') }}
      </b-button>
    </div>

    <div v-if="items.length > 0" class="group-edit item-wrapper">
      <div v-for="item in items">
        <div class="group-edit">{{ genItemText(item) }}
          <feather-icon
            icon="XIcon" size="20" class="cursor-pointer box-close"
            @click="deleteItem(item[slot_config.item_id_name])"
          />
        </div>
      </div>
    </div>

    <validation-observer
      tag="b-row"
    >
      <validation-provider
        tag="div"
        class="w-100"
        #default="{ errors }"
        :name="$t(slot_config.label)"
        :rules="slot_config.rule"
        :customMessages="slot_config.custom_messages"
      >
        <input type="hidden" :value="'_'.repeat(items.length)"/>
        <b-col cols="12" v-show="errors.length !== 0"><small class="text-danger">{{ errors[0] }}</small></b-col>
      </validation-provider>
    </validation-observer>

    <modal
      :title="$t('geo_fencing.modal_device_title')"
      :confirm-fun="confirmModal"
      :cancel-fun="hideModal"
      :visible="isShowModal"
      :ok-only="true"
      :no-close-on-backdrop="true"
    >
      <b-row v-if="false" style="margin-bottom: 0.8rem">
        <b-col cols="12">
          <b-input-group>
            <b-form-input
              :placeholder="$t('group.search_member_tips')"
              v-model="searchContent"
              @keyup.enter="getModalItems"
            />
            <b-input-group-append @click="getModalItems">
              <b-button variant="primary">
                <feather-icon icon="SearchIcon"/>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <div class="pb-1 pl-1 pr-1 group-edit" style="height: 200px; overflow-y: scroll">
        <b-row v-if="modalItems.length !== 0">
          <b-col class="mt-1">
            <b-form-checkbox
              :checked="isSelectAll"
              @change="toggleAllSelect"
            >
              {{ $t('common.select_all') }}
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6" v-for="modalItem in modalItems" class="mt-1">
            <b-form-checkbox
              :value="modalItem.deviceMAC"
              v-model="modalItemTagsSelected"
              @change="changeItem(modalItem)"
            >
              {{ genItemText(modalItem) }}
            </b-form-checkbox>
          </b-col>
        </b-row>
      </div>
    </modal>

  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import '@validations'
import Modal from '@/components/Modal'
import _ from 'lodash'
// import vSelect from 'vue-select'
// import common from '@/common'

export default {
  name: 'FieldModalSelector',
  components: {
    ValidationProvider,
    ValidationObserver,
    Modal,
    // vSelect,
  },
  props: {
    rowData: { type: Object },
    rootItem: { type: Object },
    facilityId: { type: Number },
  },
  data() {
    return {
      items: [],
      slot_config: Object.assign({}, this.rootItem.slot_config),

      modalItems: [],
      modalItemTagsSelected: [],
      isShowModal: false,
      searchContent: '',
      isGettingModalOptions: false
    }
  },
  computed: {
    isSelectAll() {
      return this.modalItemTagsSelected.length > 0 && this.modalItemTagsSelected.length === this.modalItems.length
    }
  },
  watch: {
    facilityId(facilityID) {
      this.items = []

      this.getModalItems()
    },
  },
  mounted() {
    this.getModalItems()
  },
  methods: {
    deleteItem(item_id) {
      for (const i in this.items) {
        if (this.items[i][this.slot_config.item_id_name] === item_id) {
          this.items.splice(i, 1)
          break
        }
      }
    },
    clearItems() {
      this.items = []
    },
    buildData() {
      this.rowData[this.rootItem.name] = []
      for (const i in this.items) {
        this.rowData[this.rootItem.name].push(this.items[i][this.slot_config.item_tag_name])
      }
      return true
    },

    showModal() {
      let device_tags = []
      for (const i in this.items) {
        device_tags.push(this.items[i][this.slot_config.item_tag_name])
      }
      this.modalItemTagsSelected = device_tags
      this.isShowModal = true
    },
    confirmModal() {
      this.isShowModal = false
    },
    hideModal() {
      this.isShowModal = false
    },
    changeItem(item) {
      const type = this.modalItemTagsSelected.includes(item[this.slot_config.item_tag_name]) ? 'add' : 'delete'

      if (type === 'add') {
        this.items.push(item)
      } else if (type === 'delete') {
        const index = this.items.findIndex(item => item[this.slot_config.item_id_name] === item[this.slot_config.item_id_name])
        this.items.splice(index, 1)
      }
    },
    getModalItems() {
      if (this.isGettingModalOptions) return false

      this.isGettingModalOptions = true
      this.$requestWehealth({
        method: 'get',
        url: this.slot_config.list_api,
        params: {
          facilityID: this.facilityId ? this.facilityId : 0,
          searchContent: this.searchContent
        }
      })
        .then(res => {
          this.modalItems = res.data[this.slot_config.list_name]
        })
        .finally(() => {
          this.isGettingModalOptions = false
        })
    },
    changeFacilityId(facilityID) {
      this.facilityId = facilityID
      this.searchContent = ''
      this.modalItemTagsSelected = []
      this.items = []
      this.getModalItems()
    },
    toggleAllSelect() {
      if (this.modalItemTagsSelected.length !== this.modalItems.length) {
        this.modalItemTagsSelected = this.modalItems.map(item => item[this.slot_config.item_tag_name])
        this.items = _.clone(this.modalItems)
      } else {
        this.modalItemTagsSelected = []
        this.items = []
      }
    },
    genItemText(item) {
      if (typeof this.slot_config.item_text_generator === 'function') {
        return this.slot_config.item_text_generator(item)
      } else {
        return item[this.slot_config.item_tag_name]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
//@import '@core/scss/vue/libs/vue-select.scss';

.item-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 0.8rem;
  padding: 0 0.8rem 0.8rem 0;

  > div {
    flex: 0 0 50%;
    box-sizing: border-box;
    padding: 0.8rem 0 0 0.8rem;

    > div {
      position: relative;
      padding: 0.5rem;
    }
  }

  .box-close {
    top: -9px;
    right: -9px;
    left: auto;
    bottom: auto;
  }
}
</style>
